<template>
  <footer
    class="relative overflow-x-hidden bg-gradient-to-r from-blue to-blue-250 pb-7 pt-16"
  >
    <svg
      class="absolute inset-0 -translate-x-1/3 opacity-10 md:translate-x-0"
      width="1479"
      height="100%"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.54639 2.57129H541.544C444.112 59.659 360.6 150.177 360.857 297.01L360.084 804.885H1.54639V2.57129Z"
        fill="#fff"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M579.435 419.929C552.628 387.785 537.678 346.897 537.678 304.725C537.678 205.721 618.614 124.718 718.107 124.718C751.358 124.718 784.608 134.49 812.446 151.719L579.435 420.186V419.929ZM858.584 192.606C885.39 224.75 900.34 265.637 900.34 307.81C900.34 406.814 819.405 487.817 719.911 487.817C685.63 487.817 651.348 477.274 622.738 459.273L858.841 192.606H858.584ZM419.884 306.267C419.884 470.588 554.175 604.564 718.88 604.564C883.586 604.564 1018.13 470.588 1018.13 306.267C1018.13 141.947 884.359 8.22835 720.427 7.9712C555.721 7.4569 419.626 141.433 419.626 306.267"
        fill="#fff"
      />
    </svg>
    <div class="container relative z-10">
      <!-- @slot for UiNewsletterFooter component -->
      <slot name="newsletterFooter" />

      <div class="mt-20 flex flex-wrap justify-between">
        <div
          class="order-2 mt-4 flex w-full flex-col pt-12 md:order-1 md:w-1/2 md:pt-0"
        >
          <UiLogo
            variant="white"
            :height="20"
          />
          <div
            v-if="$slots.bottomLeft"
            class="mt-16"
          >
            <!-- @slot for UiFooterSocial component -->
            <slot name="bottomLeft" />
          </div>
        </div>
        <!-- @slot for UiFooterLinks component -->
        <slot name="bottomRight" />
      </div>
      <p
        v-if="props.textCopyright"
        class="pt-20 text-sm text-white"
      >
        {{ props.textCopyright }}
      </p>
    </div>
  </footer>
</template>

<script setup lang="ts">
import UiLogo from '../UiLogo/UiLogo.vue'

interface UiFooterPageInterface {
  textCopyright?: string
  title?: string
  description?: string
}

const props = defineProps<UiFooterPageInterface>()
</script>
